.Materials {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.Materials > h1 {
	display: flex;
	margin: 2rem;
	font-size: 1.4em;
}

.Materials > p {
	display: flex;
	margin: 0;
	font-size: 1.2em;
}

.Materials > ul {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 1rem 0 0 0;
	padding: 0;
}

.Materials > ul > li {
	display: flex;
	margin: 1rem;
	padding: 0;
}
