.Submit {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.Submit > h1 {
	display: flex;
	font-size: 1.4em;
	margin: 2rem;
}

.Submit > p {
	display: flex;
	font-size: 1.2em;
	margin: 0;
}
