.AppRoot {
	position: relative;
	min-height: 100vh;
}

.AppRoot > header {
	top: 0;
	width: 100%;
	height: 2.5rem;
}

.AppRoot > main {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: calc(4vmin) 0 calc(6rem + 4vmin) 0;
}

.AppRoot > footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 6rem;
	padding-top: 1.5rem;
}
