.Disclaimer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.Disclaimer > h1 {
	display: flex;
	font-size: 1.4em;
	margin: 2rem 2rem 1rem 2rem;
}

.Disclaimer > p {
	display: flex;
	font-size: 1.2em;
	margin: 1rem;
	max-width: 32rem;
}
