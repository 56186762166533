.Footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: black;
}

.Footer > nav {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.Footer > nav.mini {
	display: none;
}

.Footer > nav > a {
	text-decoration: none;
	color: inherit;
	font-size: 1rem;
}

.Footer > nav > a + a {
	margin-left: 1.5rem;
}

.Footer > div.credit {
	margin-top: 1.5rem;
	display: flex;
	flex-direction: row;
}

.Footer > div.credit > div.logo {
	width: 0.8rem;
	height: 0.8rem;
	background-image: url('../assets/images/area23-logo-tiny-black.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-top: 2px;
}

.Footer > div.credit > p {
	display: flex;
	font-style: italic;
	font-size: 0.8rem;
	margin: 0 0 0 0.2rem;
}

.Footer > div.credit > a {
	display: flex;
	font-style: italic;
	font-size: 0.8rem;
	color: black;
	margin: 0 0 0 0.2rem;
}

@media screen and (max-width: 600px) {
	.Footer > nav.full {
		display: none;
	}

	.Footer > nav.mini {
		display: flex;
	}
}
