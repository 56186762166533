.Header {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-top: 2rem;
}

.Header > h1 {
	color: black;
	font-size: calc(1.2rem + 1vmin);
	font-weight: bold;
	margin: 0;
	text-align: center;
}

.Header > h1 > em {
	font-size: 1.5em;
	font-style: normal;
}

.Header > h1 > em.spock {
	color: #2222cc;
}

.Header > h1 > em.not {
	color: #cc2222;
}
