.Rate {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.Rate > aside {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #cccccc;
	margin: 2rem;
	padding: 1em;
	font-size: 0.9em;
}

.Rate > aside > h1 {
	margin: 0 0 0 0.25em;
}

.Rate > aside > h1 > em {
	font-size: 1.2em;
	font-style: normal;
}

.Rate > aside > h1 > em.spock {
	color: #2222cc;
}

.Rate > aside > h1 > em.not {
	color: #cc2222;
}

.Rate > aside > p {
	margin: 0.5rem 0 0 0;
}

.Rate > aside > p.others {
	font-weight: bold;
}

.Rate > aside > p.average {
	font-size: 1.8em;
	color: #2222cc;
	font-weight: bold;
}

.Rate > aside > p.rating {
	font-size: 0.9em;
}

.Rate > aside > img {
	max-width: 6rem;
	margin: 1rem 0 1rem 0;
}

.Rate > div.spock {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
}

.Rate > div.spock > section.rate {
	display: flex;
	max-width: 24rem;
	width: 95vw;
	flex-direction: column;
	align-items: center;
	background-color: #ffcc33;
	margin: 1em 0 0 0;
	padding: 0.25em;
	border: double 0.25em black;
}

.Rate > div.spock > section.rate > p {
	font-size: 0.8em;
	font-style: italic;
	margin: 0;
}

.Rate > div.spock > section.rate > form {
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.Rate > div.spock > section.rate > form > div.ratingGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0;
}

.Rate > div.spock > section.rate > form > div.ratingGroup > div.rating {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0.25em;
}

.Rate > div.spock > section.rate > form > div.ratingGroup > div.rating > input {
	display: flex;
	margin: 0;
}

.Rate > div.spock > section.rate > form > div.ratingGroup > div.rating > label {
	display: flex;
	margin: 0 0 0 0.25em;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 0.9em;
}

.Rate > div.spock > section.rate > div.rateRange {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.Rate > div.spock > section.rate > div.rateRange > p.not {
	display: flex;
	font-weight: bold;
	color: #cc2222;
	margin: 0 0.5em 0 0.5em;
}

.Rate > div.spock > section.rate > div.rateRange > div.gradient {
	display: flex;
	flex: 1;
	height: 0.3em;
	background-image: linear-gradient(to right, #cc2222 , #2222cc);
}

.Rate > div.spock > section.rate > div.rateRange > p.spock {
	display: flex;
	font-weight: bold;
	color: #2222cc;
	margin: 0 0.5em 0 0.5em;
}

.Rate > div.spock > div.showMe {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 1em 0 1em 0;
}

.Rate > div.spock > div.showMe > p {
	margin: 0 0.25em 0 0;
}

.Rate > div.spock > div.showMe > select {
	font-family: sans-serif;
}

.Rate > div.spock > img {
	min-width: 18rem;
	max-width: 28rem;
}

.Rate > div.spock > div.share {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 0.5em;
}

.Rate > div.spock > div.share > p {
	margin: 0;
}

.Rate > div.spock > div.share > a {
	display: flex;
	margin: 0 0 0 0;
	font-weight: bold;
	text-decoration: none;
	color: #1155cc;
}

@media screen and (max-width: 600px) {
	.Rate {
		flex-direction: column;
	}

	.Rate > div.spock > img {
		max-width: 95vw;
	}
}
